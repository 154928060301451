<template>
    <div>
        <Editor :url="url" :fields="fields" :filters="filters"
                icon="mdi-text-box-check-outline"
                testo-list="Lista checklist"
                testo-insert="Nuova checklist"
                testo-update="Modifica checklist"
                testo-delete="Vuoi cancellare la checklist?">
            <template #actions="{item}">
                <v-btn class="mr-2" small color="secondary" @click.prevent="modifica(item)">Requisiti</v-btn>
            </template>
            <template #form="{instance}">
                <v-text-field v-model="instance.nome" label="Nome"></v-text-field>
            </template>
        </Editor>
        <v-dialog v-model="dialogRequisiti" width="50%">
            <v-card class="pa-3">
                <v-card-title>
                    <span class="headline">Modifica requisiti {{ item.nome }}</span>
                </v-card-title>
                <v-card-text>
                    <v-select filled label="Selezionare il requisito da aggiungere" v-model="selected"
                              :items="requisitiFiltered"></v-select>
                    <v-data-table dense :headers="headers" :items="requisitiSelected" :disable-pagination="true" hide-default-footer>
                        <template #item.codice="{item}">
                            <span>{{ item.codice }}</span>
                            <Confirm message="Modifica codice" :item="item" @confirm="salvaCodice">
                                <template #activator="{on, attrs}">
                                    <v-btn icon v-on="on" v-attrs="attrs">
                                        <v-icon small>mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <template #default>
                                    <v-text-field v-model="item.codice" label="Codice"></v-text-field>
                                </template>
                            </Confirm>
                        </template>
                        <template #item.actions="{item}">
                            <v-btn icon color="primary" class="mr-2" small @click="moveRequisito(item.id, -1)">
                                <v-icon>mdi-arrow-up</v-icon>
                            </v-btn>
                            <v-btn icon color="primary" class="mr-2" small @click="moveRequisito(item.id, +1)">
                                <v-icon>mdi-arrow-down</v-icon>
                            </v-btn>
                            <v-btn icon color="error" small @click="delRequisito(item.id)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="gray" @click="dialogRequisiti=false">Annulla</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="conferma">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
import Editor from "@/components/Editor";
import Confirm from "@/components/Confirm";

export default {
    data: () => ({
        url: '/checklist/checklist/',
        fields: [
            {text: 'Nome', value: 'nome'},
        ],
        filters: [
            {field: 'nome', label: 'Nome'},
        ],
        selected: null,
        requisiti: [],
        requisitiSelected: [],
        item: {},
        dialogRequisiti: false,
        headers: [
            {text: 'Cod.Cat.', value: 's_requisito.s_categoria.codice', sortable: false},
            {text: 'Codice', value: 'codice', sortable: false},
            {text: 'Descrizione', value: 's_requisito.descrizione', sortable: false},
            {text: 'Ordine', value: 'ordine', sortable: false},
            {text: '', value: 'actions', align: 'right', sortable: false},
        ],
    }),
    mounted() {
        this.initRequisiti();
    },
    watch: {
        selected(value) {
            if (!value) return;
            this.addRequisito(value);
        }
    },
    methods: {
        async initRequisiti() {
            const res = await this.$http.get('/checklist/requisito-completo/');
            if (res.ok) {
                this.requisiti = res.result.data.map(item => ({text: item.descrizione, value: item.id}));
            }
        },
        async modifica(item) {
            this.item = item;
            this.dialogRequisiti = true;
            await this.refreshRequisiti();
        },
        async refreshRequisiti() {
            const res = await this.$http.get(`/checklist/${this.item.id}/requisito/`);
            if (res.ok) {
                this.requisitiSelected = res.result.data;
            }
            this.selected = null;
        },
        async addRequisito(value) {
            await this.$http.post(`/checklist/${this.item.id}/requisito/`, {
                checklist: this.item.id,
                requisito: value,
                ordine: this.requisitiSelected.length + 1,
            });
            await this.refreshRequisiti();
        },
        async delRequisito(value) {
            await this.$http.delete(`/checklist/${this.item.id}/requisito/${value}/`);
            await this.refreshRequisiti();
        },
        async moveRequisito(value, delta) {
            await this.$http.post(`/checklist/${this.item.id}/requisito/${value}/move/`, {delta});
            await this.refreshRequisiti();
        },
        conferma() {
            this.dialogRequisiti = false;
        },
        async salvaCodice(item) {
            await this.$http.patch(`/checklist/${this.item.id}/requisito/${item.id}/`, item);
        }
    },
    computed: {
        requisitiFiltered() {
            const ids = this.requisitiSelected.map(item => item.requisito);
            return this.requisiti.filter(item => !ids.includes(item.value));
        }
    },
    components: {
        Editor,
        Confirm
    }
}
</script>