var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Editor',{attrs:{"url":_vm.url,"fields":_vm.fields,"filters":_vm.filters,"icon":"mdi-text-box-check-outline","testo-list":"Lista checklist","testo-insert":"Nuova checklist","testo-update":"Modifica checklist","testo-delete":"Vuoi cancellare la checklist?"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.modifica(item)}}},[_vm._v("Requisiti")])]}},{key:"form",fn:function(ref){
var instance = ref.instance;
return [_c('v-text-field',{attrs:{"label":"Nome"},model:{value:(instance.nome),callback:function ($$v) {_vm.$set(instance, "nome", $$v)},expression:"instance.nome"}})]}}])}),_c('v-dialog',{attrs:{"width":"50%"},model:{value:(_vm.dialogRequisiti),callback:function ($$v) {_vm.dialogRequisiti=$$v},expression:"dialogRequisiti"}},[_c('v-card',{staticClass:"pa-3"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Modifica requisiti "+_vm._s(_vm.item.nome))])]),_c('v-card-text',[_c('v-select',{attrs:{"filled":"","label":"Selezionare il requisito da aggiungere","items":_vm.requisitiFiltered},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.requisitiSelected,"disable-pagination":true,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.codice",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.codice))]),_c('Confirm',{attrs:{"message":"Modifica codice","item":item},on:{"confirm":_vm.salvaCodice},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({directives:[{name:"attrs",rawName:"v-attrs",value:(attrs),expression:"attrs"}],attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"default",fn:function(){return [_c('v-text-field',{attrs:{"label":"Codice"},model:{value:(item.codice),callback:function ($$v) {_vm.$set(item, "codice", $$v)},expression:"item.codice"}})]},proxy:true}],null,true)})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){return _vm.moveRequisito(item.id, -1)}}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){return _vm.moveRequisito(item.id, +1)}}},[_c('v-icon',[_vm._v("mdi-arrow-down")])],1),_c('v-btn',{attrs:{"icon":"","color":"error","small":""},on:{"click":function($event){return _vm.delRequisito(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"gray"},on:{"click":function($event){_vm.dialogRequisiti=false}}},[_vm._v("Annulla")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.conferma}},[_vm._v("Conferma")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }